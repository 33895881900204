* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html,
#root {
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Content Container: Flex layout */
.content-container {
  display: flex;
  flex: 1;
}

/* Main Content: Leaflet Map */
.main-content {
  flex: 1;
  display: flex;
  align-items: center;
}
