@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Sohne.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SohneBold";
  src: url("./assets/fonts/SohneBreit-Kraftig.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "BelweBold";
  src: url("./assets/fonts/BelweBoldBT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BaltoBold";
  src: url("./assets/fonts/baltoweb-bold.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BaltoBook";
  src: url("./assets/fonts/baltoweb-book.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BaltoMedium";
  src: url("./assets/fonts/baltoweb-medium.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Sohne", Avenir, sans-serif;
}

#header-logo {
  font-family: "BelweBold", serif;
}

.menu-active::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  animation: dotAppear 0.2s ease forwards 0.2s;
}

button:hover.menu-active::after {
  visibility: visible;
}

@keyframes dotAppear {
  to {
    opacity: 1;
    bottom: -12px;
  }
}

.submenu-active {
  position: relative;
}

.submenu-active::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 4px;
  top: 31px;
  background-color: red;
  border-radius: 4px 4px 0 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  animation: dotAppear 0.2s ease forwards 0.2s;
}

/* maplibre */
.map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 77px); /* adjust as needed */
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}
